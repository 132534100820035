import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoAccessDrawer from './NoAccessDrawer';
import { off, on } from '../../../../redux/actions/ui/switches';
import { isLogged } from '../../../../redux/slices/userContext/selectors';
import './stylesheet.scss';

const ID_DRAWER = 'id_drawer_reports';

const LoggedLink = ({ slug, reportType, loginRequired, children, target, defaultOpen = false }) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLogged);
  // pas trouvé de moyen de partager la route entre rails et js
  const url = `/artprice-reports/pdf/${reportType}/${slug}.pdf`;

  const locked = loginRequired && !logged;

  const handleOpenDrawer = () => {
    dispatch(on(ID_DRAWER));
  };
  const handleCloseDrawer = () => {
    dispatch(off(ID_DRAWER));
  };

  useEffect(() => {
    if (defaultOpen) {
      dispatch(on(ID_DRAWER));
    }
  }, []);

  return (
    <div className="report-reports-loggedLink">
      {locked && (
        <>
          <NoAccessDrawer drawerId={ID_DRAWER} onClose={handleCloseDrawer} />
          <a className="link" onClick={handleOpenDrawer}>
            {children}
          </a>
        </>
      )}
      {!locked && (
        <a href={url} target={target} className="link">
          {children}
        </a>
      )}
    </div>
  );
};

export default LoggedLink;
