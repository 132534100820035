import { isEmpty, noop } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getEmail, getEmailStatus, getIsChildCustomer, getLastSubscription, isLogged, isLoginAuto } from '../../../redux/slices/userContext/selectors';
import { getSessionStorage, setSessionStorage } from '../../../utils/storage';
import { CUSTOMER_EMAIL_STATUS_NONVERIFIED, CUSTOMER_VERIFICATION_STATUS_INPROGRESS } from '../../../constants/rails';
import ContentNoEmail from './contents/NoEmail';
import ContentDefault from './contents/Default';
import ModalTour from '../../common/ModalTour';
import ContentLocked from './contents/Locked';
import './stylesheet.scss';

const CONFIG_KEY = 'emailValidationNeededConfig';
const DEFAULT_LOCALE_CONFIG = Object.freeze({ closed: undefined });

const EmailValidationNeededModal = () => {
  const [closeFunc, setCloseFunc] = useState(undefined);
  const email = useSelector(getEmail);
  const logged = useSelector(isLogged);
  const emailStatus = useSelector(getEmailStatus);
  const lastSubscription = useSelector(getLastSubscription);
  const isChildCustomer = useSelector(getIsChildCustomer);
  const loginAuto = useSelector(isLoginAuto);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!logged) return;

    if (emailStatus !== CUSTOMER_EMAIL_STATUS_NONVERIFIED && emailStatus !== CUSTOMER_VERIFICATION_STATUS_INPROGRESS) return;
    if (window?.location?.pathname?.includes('validate_email') || window?.location?.pathname?.includes('account') || window?.location?.pathname?.includes('contact')) return;
    if (loginAuto) return;

    const isChild = lastSubscription?.isChildSubscription || isChildCustomer;
    if (email) {
      if (new Date(lastSubscription?.dates?.dtExpire) > new Date(new Date().setFullYear(new Date().getFullYear() - 1)) && lastSubscription?.usersCount === 1 && !isChild) {
        setSections([<ContentLocked />]);
      } else if (emailStatus === CUSTOMER_EMAIL_STATUS_NONVERIFIED) {
        const { closed } = getSessionStorage(CONFIG_KEY, DEFAULT_LOCALE_CONFIG);
        if (!closed) {
          setCloseFunc(() => noop);
          setSessionStorage(CONFIG_KEY, { closed: new Date() });
          setSections([<ContentDefault />]);
        }
      }
    } else if (!isChild) {
      // compte fils : email vide autorisé
      setCloseFunc(() => noop);
      setSessionStorage(CONFIG_KEY, { closed: new Date() });
      setSections([<ContentNoEmail />]);
    }
  }, [email, emailStatus, logged]);

  if (!isEmpty(sections)) {
    return <ModalTour className={classNames({ EmailValidationNeededTour: true, locked: !closeFunc })} sections={sections} onClose={closeFunc} />;
  }

  return null;
};

export default EmailValidationNeededModal;
