import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { number } from 'prop-types';
import Alert from '../../../../../../common/Alert';
import { clearStoreImageErrors as clearStoreImageErrorsAction } from '../../../../../../../redux/actions/marketplace/stores';
import { getErrorCodeForStoreImage as getErrorCodeForStoreImageSelector } from '../../../../../../../redux/selectors/marketplace/stores';

const Errors = ({ errorCode, clearErrors }) => {
  if (errorCode) {
    switch (errorCode) {
      case 404:
        return <Alert onClick={() => clearErrors()} message={i18next.t('marketplace.stores.edit.form.images.image.errors.m1')} />;
      case 413:
        return <Alert onClick={() => clearErrors()} message={i18next.t('marketplace.stores.edit.form.images.image.errors.m2')} />;
      case 406:
        return <Alert onClick={() => clearErrors()} message={i18next.t('marketplace.stores.edit.form.images.image.errors.m3')} />;
      case 500:
        return <Alert onClick={() => clearErrors()} message={i18next.t('marketplace.stores.edit.form.images.image.errors.m4')} />;
      default:
        return <Alert onClick={() => clearErrors()} message={`Erreur ${errorCode}`} />;
    }
  }

  return null;
};

Errors.defaultProps = {
  errorCode: undefined,
};

Errors.propTypes = {
  errorCode: number,
};

function mapStateToProps(state, ownProps) {
  const { idstore, imageId } = ownProps;

  return {
    errorCode: getErrorCodeForStoreImageSelector(state, { idstore, imageSha1: imageId }),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { idstore, imageId } = ownProps;

  return {
    clearErrors: () => dispatch(clearStoreImageErrorsAction({ id: idstore, imageSha1: imageId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
