import React from 'react';
import { IntlProvider } from 'react-intl';
import Table from '../index';
import ReduxProvider from '../../../../providers/ReduxProvider';

const TableProvided = ({ locale = 'en', data = [], loginRequired }) => (
  <IntlProvider locale={locale}>
    <ReduxProvider>
      <Table data={data} loginRequired={loginRequired} />
    </ReduxProvider>
  </IntlProvider>
);

export default TableProvided;
