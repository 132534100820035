import { noop } from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import Arrow from './Arrow';
import './stylesheet.scss';
import Cell from './Cell';
import { isLogged } from '../../../../redux/slices/userContext/selectors';
import LoginNeeded from './LoginNeeded';

const Table = ({ data, loginRequired }) => {
  const logged = useSelector(isLogged);
  const locked = loginRequired && !logged;

  const { definition, data: tableData } = data;
  const [orderedData, setOrderedData] = useState([]);
  const defaultOrderColumn = definition.findIndex(d => d.sortable === 2);
  const [order, setOrder] = useState({ direction: 'desc', on: defaultOrderColumn });

  const handleClick = on => {
    if (order.on === on) {
      setOrder({ ...order, direction: order.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setOrder({ on, direction: 'asc' });
    }
  };

  useEffect(() => {
    setOrderedData(
      tableData.toSorted((a, b) => {
        let compareResultAsc = a[order.on].sort.localeCompare(b[order.on].sort, undefined, { numeric: true });
        if (compareResultAsc === 0) compareResultAsc = a[defaultOrderColumn].sort.localeCompare(b[defaultOrderColumn].sort, undefined, { numeric: true });

        if (order.direction === 'asc') return compareResultAsc;
        return compareResultAsc * -1;
      }),
    );
  }, [tableData, order]);

  return (
    <div className="reports-report-table">
      <table>
        <thead>
          <tr>
            {definition.map((def, index) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <td key={def.title} onClick={def.sortable > 0 ? () => handleClick(index) : noop} className={classNames({ sortable: def.sortable })}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 10px', columnGap: 5 }}>
                  <div>{def.title}</div>
                  {def.sortable > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Arrow order={order} index={index} />
                    </div>
                  )}
                </div>
              </td>
            ))}
          </tr>
        </thead>
        {!locked && (
          <tbody>
            {orderedData.map(row => (
              <tr key={uuidv4()}>
                {row.map((cell, index) => (
                  <Cell definition={definition[index]} value={cell} key={uuidv4()} />
                ))}
              </tr>
            ))}
          </tbody>
        )}
        {locked && (
          <tbody>
            <tr>
              <td colSpan={definition.length}>
                <LoginNeeded />
              </td>
            </tr>
          </tbody>
        )}
        <tfoot>
          <tr>
            <td style={{ textAlign: 'center' }} colSpan={definition.length}>
              © Artprice
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
