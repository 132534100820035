import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, string, func, number } from 'prop-types';
import { FileSelector } from '@artprice/react-upload';
import Errors from './Errors';
import Cropper from './Cropper';
import Placeholder from './Placeholder';
import './stylesheets.scss';
import { getUpdateStoreImage as getUpdateStoreImageSelector } from '../../../../../../redux/selectors/marketplace/stores';
import {
  updateStoreImage as updateStoreImageAction,
  deleteImage as deleteImageAction,
  uploadImage as uploadImageAction,
  setPrimaryImage as setPrimaryImageAction,
} from '../../../../../../redux/actions/marketplace/stores';
import { isOn as isOnSelector } from '../../../../../../redux/selectors/ui/switches';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, crop: false };
    this.ref = React.createRef();
  }

  handleFileSelectorOnChange(file) {
    const { uploadImage, updateStoreImage, idx } = this.props;

    let fileToUpload = file;
    if (Array.isArray(fileToUpload)) {
      fileToUpload = fileToUpload[0];
    }

    this.setState({ loaded: false });
    updateStoreImage(true);
    uploadImage(fileToUpload);
    if (idx === 0) {
      this.setState({ crop: true });
    }
  }

  handleCropperOnSave({ blob }) {
    const { uploadImage } = this.props;
    this.toggleCrop();
    uploadImage(blob);
  }

  toggleCrop = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.setState(prevState => ({ crop: !prevState.crop }));
  };

  handlePlaceholderOnload = () => {
    const { updateStoreImage } = this.props;
    this.setState({ loaded: true });
    updateStoreImage(false);
  };

  handlePlaceholderOnError = () => {
    const { updateStoreImage } = this.props;
    this.setState({ loaded: true });
    updateStoreImage(false);
  };

  handleSetPrimaryImage = (imageId, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const { setPrimaryImage } = this.props;
    setPrimaryImage(imageId);
  };

  handleDeleteImage = (imageId, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const { deleteImage } = this.props;
    deleteImage(imageId);
  };

  placeholderComponent = () => {
    const { imageUrl, updateStoreImage, updatingImage, idx } = this.props;
    return (
      <Placeholder
        imageUrl={imageUrl}
        ref={this.ref}
        idx={idx}
        onLoad={this.handlePlaceholderOnload}
        onError={this.handlePlaceholderOnError}
        updatingImage={updatingImage}
        onLoadStart={() => updateStoreImage(true)}
        actions={this.actionsComponent}
      />
    );
  };

  actionsComponent = () => {
    const { imageId, isDeleting, imageUrl, idx } = this.props;
    const { loaded } = this.state;

    if (loaded && imageUrl) {
      return (
        <div className="actions">
          {idx > 0 && (
            <div className="action">
              <i className="fa fa-star-o" role="presentation" onClick={event => this.handleSetPrimaryImage(imageId, event)} />
            </div>
          )}
          <div className="action">
            {isDeleting && <i className="fa fa-spin fa-spinner" />}
            {!isDeleting && <i className="fa fa-trash" role="presentation" onClick={event => this.handleDeleteImage(imageId, event)} />}
          </div>
          <div className="action">
            <i className="fa fa-crop" role="presentation" onClick={this.toggleCrop} />
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    const { imageId, className, updatingImage, cropAspect, idstore } = this.props;
    const { loaded, crop } = this.state;

    return (
      <div className={`marketplace-store-edit-form-images-image ${className !== null ? className : ''}`}>
        <FileSelector onChange={file => this.handleFileSelectorOnChange(file)} placeholder={this.placeholderComponent} />

        {crop && loaded && <Cropper cropAspect={cropAspect} imageRef={this.ref} onCancel={() => this.toggleCrop()} onSave={p => this.handleCropperOnSave(p)} />}

        {updatingImage && <i className="updating-image fa fa-spinner fa-spin fa-5x fa-fw" />}
        <Errors imageId={imageId} idstore={idstore} />
      </div>
    );
  }
}

Image.defaultProps = {
  imageUrl: undefined,
  imageId: undefined,
  cropAspect: undefined,
  className: null,
  updatingImage: false,
};

Image.propTypes = {
  imageId: string,
  imageUrl: string,
  setPrimaryImage: func.isRequired,
  uploadImage: func.isRequired,
  deleteImage: func.isRequired,
  idstore: number.isRequired,
  idx: number.isRequired,
  updatingImage: bool,
  className: string,
  cropAspect: number,
};

function mapStateToProps(state, ownProps) {
  const { idstore, imageId } = ownProps;

  return {
    isDeleting: isOnSelector(state, { id: imageId }),
    updatingImage: getUpdateStoreImageSelector(state, { idstore, imageSha1: imageId }),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { idstore, imageId } = ownProps;

  return {
    deleteImage: id => dispatch(deleteImageAction(idstore, id)),
    setPrimaryImage: id => dispatch(setPrimaryImageAction(idstore, id)),
    uploadImage: file => dispatch(uploadImageAction(idstore, file, imageId)),
    updateStoreImage: loading => dispatch(updateStoreImageAction({ id: idstore, imageSha1: imageId, loading })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Image);
