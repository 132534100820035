import React from 'react';
import A18n from '../../../common/A18n';
import ProvidedLoggedLink from '../LoggedLink/ProvidedLoggedLink';

const PdfPanel = ({ loginRequired, slug, reportType, imgUrl, target }) => (
  <>
    <div className="cover">
      <ProvidedLoggedLink slug={slug} reportType={reportType} target={target} loginRequired={loginRequired}>
        <img alt="reportcover" src={imgUrl} />
      </ProvidedLoggedLink>
    </div>
    <div className="link">
      <ProvidedLoggedLink slug={slug} reportType={reportType} target={target} loginRequired={loginRequired}>
        <i className="fa fa-file-pdf-o" /> <A18n rsx="estimate.show.m5" />
      </ProvidedLoggedLink>
    </div>
  </>
);

export default PdfPanel;
