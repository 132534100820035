import React from 'react';
import LoggedLink from '../index';
import ReduxProvider from '../../../../providers/ReduxProvider';

const ProvidedLoggedLink = ({ slug, reportType, loginRequired, target, children, defaultOpen }) => (
  <ReduxProvider>
    <LoggedLink slug={slug} reportType={reportType} loginRequired={loginRequired} target={target} defaultOpen={defaultOpen}>
      {children}
    </LoggedLink>
  </ReduxProvider>
);

export default ProvidedLoggedLink;
