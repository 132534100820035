import React from 'react';
import i18next from 'i18next';
import LoggedLink from '../../LoggedLink';
import './stylesheet.scss';

const LoginNeeded = () => (
  <LoggedLink defaultOpen loginRequired>
    <div className="reports-report-table-loginNeeded">
      <span dangerouslySetInnerHTML={{ __html: i18next.t('artmarketinsight.reports.m13') }} />
    </div>
  </LoggedLink>
);

export default LoginNeeded;
