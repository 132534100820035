import React from 'react';
import A18n from '../../../common/A18n';
import ProvidedLoggedLink from '../LoggedLink/ProvidedLoggedLink';

const PdfButton = ({ loginRequired, slug, reportType, target }) => (
  <ProvidedLoggedLink slug={slug} reportType={reportType} target={target} loginRequired={loginRequired}>
    <A18n rsx="reports.report.pdfbutton.m1" />
  </ProvidedLoggedLink>
);

export default PdfButton;
